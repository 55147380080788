/* eslint-disable */
import React from "react";
import axios from "axios";
import { DumbForgotPasswordModalDT } from "../../../../../profile/components/AccountAccess/ForgotPasswordModalDT";

export const ForgotPasswordWrapper = () => {
  const sendEmail = (email, successCallback, errorCallback) => {
    const lambdaHost = sessionStorage.getItem('lambdaHost');
    const fastlyHost = sessionStorage.getItem('fastlyUCAHost') || lambdaHost;
    axios
      .post(`${fastlyHost}/accounts/v1/forgot-password`, { email })
      .then((successRes) => {
        if (successRes) successCallback()
        else throw('empty response from lambda');
      }).catch(error=>{
        if (error?.response?.data?.message === 'The profile is not confirmed. Please confirm first.') {
          window.location.href = `/my/verify-email?id=${btoa(email)}`;
        }
        errorCallback
      }
      );
  };

  return (
    <DumbForgotPasswordModalDT
        modalClassName="panel-forgot-pw"
        sendForgotPwEmail={sendEmail}
    />
  );
};

export default ForgotPasswordWrapper;
